@import '../../globalstyles/variables.scss';

.home-body {
    text-align: center;
    font-family: $font-main;
}

.aboutMe {
    background-color: rgb(0, 0, 0);
    color: white;
    border-radius: 10px;
    -webkit-box-shadow: 0px 6px 26px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 6px 26px -5px rgba(0,0,0,0.75);
    box-shadow: 0px 6px 26px -5px rgba(0,0,0,0.75);
}

.profilePicture {
    border-radius: 10px;
    max-width: 100%;
    height: auto;
    -webkit-box-shadow: 0px 6px 26px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 6px 26px -5px rgba(0,0,0,0.75);
    box-shadow: 0px 6px 26px -5px rgba(0,0,0,0.75);
}