@import '../../globalstyles/variables.scss';

.CV-body {
    font-family: $font-main;
}

.headline {
    color: white;
    background-color: rgb(37, 37, 37);
    border-radius: 5px;
}

.headline2 {
    color: white;
    background-color: rgb(71, 71, 71);
    border-radius: 5px;
}

// .dl-link {

//     margin-top: 0;
//     margin-bottom: 0;
// }


